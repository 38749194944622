<template>
	<ViewLayout>
		<template #header-title>
			{{ 'Cookie Categories' | useLabels }}
		</template>
		<template #header-caption>
			{{ 'Add and manage cookie categories' | useLabels }}
		</template>
		<template #header-after>
			<Tabs
				:tab.sync="tab"
				:tabs="tabs"
			/>
		</template>
		<template #content>
			<TabItems
				:tab="tab"
				:tabs="tabs"
			>
				<template #0>
					<CookieCategoriesTab
						:cookie-categories="cookieCategories"
						@load="loadCookieCategories"
					/>
				</template>
				<template #1>
					<CookieCategoryTranslationsTab
						:cookie-categories="cookieCategories"
						@load="loadCookieCategories"
					/>
				</template>
			</TabItems>
		</template>
	</ViewLayout>
</template>

<script>
import ViewLayout from '../../../../../../../shared/layouts/view-layout.vue'
import Tabs from '../../../../../../../shared/components/tabs.vue'
import TabItems from '../../../../../../../shared/components/tab-items.vue'
import { getCookieCategories } from '../../../../../../../shared/utils/api/cookies/cookie-categories.js'
import CookieCategoriesTab from './cookie-categories-tab.vue'
import CookieCategoryTranslationsTab from './cookie-category-translations-tab.vue'

export default {
	components: {
		ViewLayout,
		Tabs,
		TabItems,
		CookieCategoriesTab,
		CookieCategoryTranslationsTab
	},
	data () {
		return {
			tabs: [{ title: 'Cookie Categories', slot: '0' }, { title: 'Cookie Category Translations', slot: '1' }],
			tab: '0',
			cookieCategories: []
		}
	},
	created () {
		this.loadCookieCategories()
	},
	methods: {
		async loadCookieCategories () {
			const { cookieCategories } = await getCookieCategories()
			this.cookieCategories = cookieCategories
		}
	}
}
</script>
