<template>
	<ValidationForm #default="{ handleSubmit }">
		<Modal width="850px">
			<template #modal-title>
				{{ isEdit ? 'Edit' : 'Create' }} Cookie Category Translation
				<v-spacer />
				<IconButton @click="$emit('close')">
					mdi-close
				</IconButton>
			</template>
			<template #modal-content>
				<div class="cassie-vertical-md">
					<v-row dense>
						<v-col cols="12">
							<TextField
								:value="cookieCategoryTranslation.languageName"
								disabled
								label="Language"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<TextField
								:value="cookieCategoryTranslation.categoryName"
								disabled
								label="Name"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<Textarea
								:value="cookieCategoryTranslation.displayText"
								auto-grow
								disabled
								label="Default Display Text"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<TextField
								v-model="translatedCategoryName"
								:disabled="!userCanCreateUpdate"
								label="Translated Cookie Category Name *"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<Froala
								v-if="userCanCreateUpdate"
								v-model="translatedDisplayText"
								title="Translated Display Text *"
								:rules="{required: true, max: 2000}"
							/>
							<Textarea
								v-else
								disabled
								:value="cookieCategoryTranslation.translatedDisplayText"
								label="Translated Display Text *"
							/>
						</v-col>
					</v-row>
				</div>
			</template>
			<template #modal-footer>
				<SecondaryActionButton @click="$emit('close')">
					{{ userCanCreateUpdate ? 'Cancel' : 'Close' }}
				</SecondaryActionButton>
				<PrimaryActionButton
					v-if="userCanCreateUpdate"
					@click="handleSubmit(submit)"
				>
					{{ !isEdit ? 'Create' : 'Save' }}
				</PrimaryActionButton>
			</template>
		</Modal>
	</ValidationForm>
</template>

<script>
import Modal from '../../../../../../../shared/components/modal.vue'
import ValidationForm from '../../../../../../../shared/components/validation-form.vue'
import TextField from '../../../../../../../shared/components/text-field.vue'
import Textarea from '../../../../../../../shared/components/textarea.vue'
import Froala from '../../../../shared/froala-editor.vue'
import SecondaryActionButton from '../../../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../../../shared/components/icon-button.vue'
import { showSnackbar } from '../../../../../../../shared/state/snackbar.js'
import { createCookieCategoryTranslation, updateCookieCategoryTranslation } from '../../../../../../../shared/utils/api/cookies/cookie-categories.js'
export default {
	components: {
		Modal,
		ValidationForm,
		IconButton,
		TextField,
		Textarea,
		Froala,
		SecondaryActionButton,
		PrimaryActionButton
	},
	props: {
		cookieCategoryTranslation: {
			type: Object,
			required: true
		},
		userCanCreateUpdate: {
			type: Boolean,
			default: false
		}
	},
	setup () {
		return {
			showSnackbar
		}
	},
	data () {
		return {
			translatedDisplayText: null,
			translatedCategoryName: null
		}
	},
	computed: {
		isEdit () {
			return this.cookieCategoryTranslation?.categoryTranslationId !== undefined && this.cookieCategoryTranslation?.categoryTranslationId !== null
		}
	},
	created () {
		if (this.isEdit) {
			this.translatedDisplayText = this.cookieCategoryTranslation?.categoryTranslation ?? ''
			this.translatedCategoryName = this.cookieCategoryTranslation?.translatedCategoryName ?? ''
		}
	},
	methods: {
		async submit () {
			const translation = {}
			translation.categoryId = this.cookieCategoryTranslation.categoryId
			translation.languageId = this.cookieCategoryTranslation.languageId
			translation.categoryName = this.translatedCategoryName
			translation.displayText = this.translatedDisplayText
			if (this.isEdit) {
				await updateCookieCategoryTranslation(translation)
			} else {
				await createCookieCategoryTranslation(translation)
			}
			showSnackbar(`Cookie Category Translation ${this.isEdit ? 'updated' : 'created'} successfully`)
			this.$emit('close')
			this.$emit('submit')
		}
	}
}
</script>
